import { createEffect, onCleanup } from 'solid-js';
import { supabase } from '../supabaseClient'

const AuthSession = (userId, setUserId, userEmail, setUserEmail) => {
  createEffect(async () => {
    const { data } = await supabase.auth.getSession();

    if (data?.session) {
      updateUserData(data.session.user);
    }

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (session) {
          updateUserData(session.user);
          console.log('session created');
        } else {
          setUserId(null);
          setUserEmail(null);
        }
      }
    );

    onCleanup(() => {
      authListener.unsubscribe();
    });
  });

  function updateUserData(user) {
    if (user.id !== userId()) {
      setUserId(user.id);
    }
    if (user.email !== userEmail()) {
      setUserEmail(user.email);
    }
  }
}

export default AuthSession;
/* @refresh reload */
import { render } from 'solid-js/web';
import { Router } from "@solidjs/router";
import App from './App';
import * as Sentry from "@sentry/browser";
import { inject } from '@vercel/analytics';
import posthog from 'posthog-js'
import Logspot from "@logspot/web";
// import 'uno.css'

inject();

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  posthog.init(import.meta.env.VITE_POSTHOG, { api_host: 'https://eu.posthog.com' })

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.01,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: "my-project-name@2.3.12",
    integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration(), new posthog.SentryIntegration(posthog, import.meta.env.VITE_SENTRY_ORG, import.meta.env.VITE_SENTRY_PROJECT)],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  Logspot.init({
    publicKey: import.meta.env.VITE_LOGSPOT_PUBLIC_KEY
  });
}

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?',
  );
}

render(() => (
  <div className='font-sans'>
    <Router>
      <App />
    </Router>
  </div>
), root);

import { supabase } from '../../supabaseClient';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { Auth } from '@supabase/auth-ui-solid';
import { createEffect, createSignal, onMount } from 'solid-js';
import * as Sentry from "@sentry/browser";
import { useSearchParams } from '@solidjs/router';
import { Show } from 'solid-js';

function NewTabNotice({ url }) {

  const openInNewTab = () => {
    window.open(url, '_blank');
  };

  return (
    <div class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div class="bg-white rounded-lg p-8 max-w-md mx-auto text-center shadow-lg">
        <h2 class="text-2xl font-bold mb-4">App Opened in New Tab</h2>
        <p class="text-gray-600 mb-6">
          The app should have automatically opened in a new tab. If it didn't, please click the button below to open it manually.
        </p>
        <button
          class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          onClick={openInNewTab}
        >
          Open in New Tab
        </button>
      </div>
    </div>
  );
}

export default function Authentication({ app_id, bundle_id, newSignup }) {
  const [searchParams] = useSearchParams();

  // const [view, setView] = createSignal("sign_in");

  // createEffect(() => {
  //   if (searchParams.sign_up) setView("sign_up");
  // })

  const [title, setTitle] = createSignal("ZAPT");
  const [isLoading, setIsLoading] = createSignal(true);
  const [subtitleType, setSubtitleType] = createSignal("default");

  async function getBundleName(bundleId) {
    const { data, error } = await supabase.from("Bundle").select("name").eq("id", bundleId).single();
    if (error) return Sentry.captureException(error);
    setTitle(data.name);
    document.title = data.name;
    setIsLoading(false);
    return
  }

  async function getAppName(appId) {
    const { data, error } = await supabase.from("App").select("name").eq("id", appId).single();
    if (error) return Sentry.captureException(error);
    setTitle(data.name);
    document.title = data.name;
    setIsLoading(false);
    return
  }

  createEffect(() => {
    if (bundle_id) {
      getBundleName(bundle_id)
      // if (newSignup()) {
      //   setView("sign_up")
      // }
      setSubtitleType("signIn");
      return;
    }
    if (app_id) {
      getAppName(app_id)
      setSubtitleType("signIn");
      return;
    }
    setIsLoading(false);
  })

  function getCurrentUrlWithoutTrailingHash() {
    let url = window.location.href;
    if (url.endsWith('#')) {
      return url.slice(0, -1);
    }
    return url;
  }

  let videoRef;

  onMount(() => {
    if (videoRef) {
      videoRef.play().catch((error) => {
        console.error('Error playing video:', error);
      });
    }
  });

  function getTagline() {
    if (searchParams.app_description_id) {
      return "Sign Up To Create Your App";
    }
    // if (window.location.hostname === "schools.zapt.ai") {
    //   return "Build the AI apps you need";
    // }
    return "Build the AI apps you need";
  }

  if (window.self !== window.top) {
    window.open(window.location.href, '_blank').focus();
    return <NewTabNotice url={window.location.href} />;
  }


  const [inputOtp, setInputOtp] = createSignal(false);
  const [email, setEmail] = createSignal("");

  async function signInWithOtp() {
    const { error } = await supabase.auth.signInWithOtp({
      email: email()
    })
    if (error) {
      Sentry.captureException("issue with sign in with otp");
      console.error('Error logging in:', error);
      return;
    }
    setInputOtp(true);
  }

  const [otp, setOtp] = createSignal("");

  async function verifyOtp() {
    const { data, error } = await supabase.auth.verifyOtp({ email: email(), token: otp(), type: 'email' })
    if (error) {
      Sentry.captureException("issue with verify otp");
      console.error('Error logging in:', error);
      return;
    }
    console.log(data);
  }




  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <video
          ref={videoRef}
          className="absolute top-0 left-0 w-full h-full object-cover"
          playsinline
          preload="metadata"
          autoplay
          muted
          loop
          id="video-background"
        >
          <source
            src="https://supabase.zapt.ai/storage/v1/object/public/zapt-branding/website-background.mp4"
            type="video/mp4"
          />
          <source
            src="https://supabase.zapt.ai/storage/v1/object/public/zapt-branding/website-background.webm"
            type="video/webm"
          />
        </video>
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-white to-indigo-50 opacity-70"></div>
      </div>
      <div className="relative z-10 w-full max-w-xl mx-auto sm:max-w-3xl">
        <Show when={isLoading()}>
          <div>Loading...</div>
        </Show>
        <Show when={!isLoading()}>
          <div className="relative px-4 bg-transparent">
            <div className="w-full mx-auto">
              <div className="text-center mb-8">
                <h1 className="text-5xl font-bold mb-4 text-gray-800">{title()}</h1>
                <Show when={subtitleType() === "default"}>
                  <div className="flex items-center justify-center">
                    <div className="w-12 h-1 bg-gradient-to-r from-purple-600 to-indigo-600 mr-4"></div>
                    <p className="text-xl text-gray-700 font-medium">{getTagline()}</p>
                    <div className="w-12 h-1 bg-gradient-to-r from-indigo-600 to-purple-600 ml-4"></div>
                  </div>
                </Show>
                <Show when={subtitleType() === "signIn"}>
                  <div className="flex items-center justify-center">
                    <div className="w-12 h-1 bg-gradient-to-r from-purple-600 to-indigo-600 mr-4"></div>
                    <p className="text-xl text-gray-700 font-medium">
                      Sign in with <a href="https://www.zapt.ai" target="_blank" rel="noopener noreferrer" className="text-purple-600 hover:text-purple-500 font-semibold transition duration-300">ZAPT</a>
                    </p>
                    <div className="w-12 h-1 bg-gradient-to-r from-indigo-600 to-purple-600 ml-4"></div>
                  </div>
                </Show>
              </div>
              <div className="w-full mx-auto text-black">
                <Auth
                  supabaseClient={supabase}
                  appearance={{
                    theme: ThemeSupa,
                    variables: {
                      default: {
                        colors: {
                          brand: '#6B46C1',
                          brandAccent: '#805AD5',
                          anchorTextColor: 'black',
                          anchorTextHoverColor: '#805AD5',
                          inputBorderFocus: '#6B46C1',
                          inputLabelText: '#4A5568',
                          messageText: '#4A5568',
                          messageTextDanger: '#E53E3E'
                        },
                      },
                    },
                  }}
                  theme="dark"
                  providers={['google', 'facebook', 'apple']}
                  // providers={['google', 'apple']}
                  // view={view()}
                  redirectTo={getCurrentUrlWithoutTrailingHash()}
                  // magicLink={true}
                  onlyThirdPartyProviders
                // view="magic_link"
                // showLinks={false}
                // authView="magic_link"
                />

                <div className="text-lg mt-14">
                  <Show when={!inputOtp()}>
                    <input type="email" placeholder="Enter email..." className="w-full box-border bg-white text-black font-bold py-2 px-4 rounded mt-4 text-lg" onInput={(e) => setEmail(e.target.value)} />
                    <button
                      onClick={signInWithOtp}
                      className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-8 rounded cursor-pointer w-full mt-4 text-lg"
                    >
                      Sign in
                    </button>
                  </Show>
                  <Show when={inputOtp()}>
                    <p className="text-gray-600 text-center text-lg mt-4">
                      A code has been sent to your email address. Please enter it below.
                    </p>
                    <input type="text" placeholder="Enter code..." className="w-full box-border bg-white text-black font-bold py-2 px-4 rounded mt-4 text-lg" onInput={(e) => setOtp(e.target.value)} />
                    <div className="mt-6 flex flex-col sm:flex-row sm:justify-between gap-6">
                      <button
                        onClick={verifyOtp}
                        className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-8 rounded cursor-pointer text-lg"
                      >
                        Verify Code
                      </button>
                      <button
                        onClick={() => setInputOtp(false)}
                        className="bg-black hover:bg-gray-900 text-white font-bold py-2 px-8 rounded cursor-pointer text-lg"
                      >
                        Back
                      </button>
                    </div>
                  </Show>
                </div>


              </div>
              <Show when={bundle_id}>
                <div className="mt-4 flex justify-center">
                  <button
                    onClick={() => {
                      window.location.href = '/';
                    }}
                    className="bg-white hover:bg-gray-100 text-black font-bold py-2 px-8 rounded cursor-pointer"
                  >
                    Back
                  </button>
                </div>
              </Show>
            </div>
          </div>
        </Show>
      </div>
    </div>
  );
}

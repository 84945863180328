import { supabase } from "../../supabaseClient";
import * as Sentry from "@sentry/browser";

export default async function checkAccess(setCanAccess, setLoading) {

    if (window.location.hostname !== 'schools.zapt.ai'){
        setCanAccess(true);
        setLoading(false);
        return;
    }

    const { data, error } = await supabase.functions.invoke("get-user-school-info");

    if (error){
        Sentry.captureException(error);
        setCanAccess(false);
        setLoading(false);
        return;
    }

    if(data.isTeacher || data.isStudent){
        setCanAccess(true);
        setLoading(false);
        return;
    }
    
    setCanAccess(false);
    setLoading(false);
    return
}
import { lazy, Show, createEffect, createSignal } from "solid-js";
import { Routes, Route } from "@solidjs/router";

import { supabase } from "../../supabaseClient";
import checkAccess from "./checkAccess";
import posthog from 'posthog-js'

// Lazy-load route components
const Home = lazy(() => import("../../routes/Home"));
const CreateApp = lazy(() => import("../../routes/CreateApp"));
const DeployedApp = lazy(() => import("../../routes/DeployedApp"));
const AppSettings = lazy(() => import("../../routes/AppSettings"));
const AppDetails = lazy(() => import("../../routes/AppDetails"));
const CreateNewApp = lazy(() => import("../../routes/CreateNewApp"));
const AiBuilder = lazy(() => import("../../routes/AiBuilder"));
const UserPlan = lazy(() => import("../../routes/UserPlan"));
const AppIcon = lazy(() => import("../../routes/AppIcon"));
const Settings = lazy(() => import("../../routes/Settings"));
const AppPrice = lazy(() => import("../../routes/AppPrice"));
const ResetPasswordForm = lazy(() => import("../../routes/ResetPasswordForm"));
const AppDesign = lazy(() => import("../../routes/AppDesign"));
const AppConfig = lazy(() => import("../../routes/AppConfig"));

const ApiEdit = lazy(() => import("../../routes/ApiEdit"));
const ApisList = lazy(() => import("../../routes/ApisList"));
const ApiKeysList = lazy(() => import("../../routes/ApiKeysList"));
const ApiKey = lazy(() => import("../../routes/ApiKey"));
const Api = lazy(() => import("../../routes/Api"));

const Databases = lazy(() => import("../../routes/Databases"));
const Database = lazy(() => import("../../routes/Database"));
const DatabaseSettings = lazy(() => import("../../routes/DatabaseSettings"));
const DatabaseData = lazy(() => import("../../routes/DatabaseData"));
const DatabaseStructureView = lazy(() => import("../../routes/DatabaseStructureView"));
const DatabaseStructureEdit = lazy(() => import("../../routes/DatabaseStructureEdit"));

const LandingPage = lazy(() => import("../../routes/LandingPage"));
const CustomDomain = lazy(() => import("../../routes/CustomDomain"));
const AppData = lazy(() => import("../../routes/AppData"));
const Bundles = lazy(() => import("../../routes/Bundles"));
const Bundle = lazy(() => import("../../routes/Bundle"));
const BundleEditApps = lazy(() => import("../../routes/BundleEditApps"));
const BundleViewApps = lazy(() => import("../../routes/BundleViewApps"));
const BundleCustomDomain = lazy(() => import("../../routes/BundleCustomDomain"));
const BundleEditPrice = lazy(() => import("../../routes/BundleEditPrice"));
// const TeamMembers = lazy(() => import("../../routes/TeamMembers"));
const BundleAllowedUsers = lazy(() => import("../../routes/BundleAllowedUsers"));
const TeacherPortal = lazy(() => import("../../routes/TeacherPortal"));
const ApiAppAccess = lazy(() => import("../../routes/ApiAppAccess"));

const CreateAppDatabase = lazy(() => import("../../routes/CreateAppDatabase"));
const CreateAppDatabaseNew = lazy(() => import("../../routes/CreateAppDatabaseNew"));
const CreateAppChangeVersion = lazy(() => import("../../routes/CreateAppChangeVersion"));
const CreateAppChangeVersionConfirm = lazy(() => import("../../routes/CreateAppChangeVersionConfirm"));
const CreateAppDeploy = lazy(() => import("../../routes/CreateAppDeploy"));
const CreateAppSteps = lazy(() => import("../../routes/CreateAppSteps"));
const CreateAppStep = lazy(() => import("../../routes/CreateAppStep"));
const CreateAppAiChanges = lazy(() => import("../../routes/CreateAppAiChanges"));
const CreateAppStepDelete = lazy(() => import("../../routes/CreateAppStepDelete"));
const CreateAppStepNextSelector = lazy(() => import("../../routes/CreateAppStepNextSelector"));

const AppBuilder = lazy(() => import("../../routes/AppBuilder"));
const AppBuilderView = lazy(() => import("../../routes/AppBuilderView"));
const AppBuilderFaq = lazy(() => import("../../routes/AppBuilderFaq"));
const AppBuilderCustomDomains = lazy(() => import("../../routes/AppBuilderCustomDomains"));
const AppBuilderAppPrice = lazy(() => import("../../routes/AppBuilderAppPrice"));
const AppBuilderAppIcon = lazy(() => import("../../routes/AppBuilderAppIcon"));
const AppBuilderSettings = lazy(() => import("../../routes/AppBuilderSettings"));
const AppBuilderEnvVars = lazy(() => import("../../routes/AppBuilderEnvVars"));
const AppBuilderViewErrors = lazy(() => import("../../routes/AppBuilderViewErrors"));
const AppBuilderViewStageDevelopment = lazy(() => import("../../routes/AppBuilderViewStage/Development"));
const AppBuilderViewStageProduction = lazy(() => import("../../routes/AppBuilderViewStage/Production"));
const AppBuilderDatabaseViewer = lazy(() => import("../../routes/AppBuilderDatabaseViewer"));

const handleSignOut = () => {
  supabase.auth.signOut();
  if (posthog.__loaded) posthog.reset()
  if (window.Intercom) window.Intercom("shutdown");
};

export default function RoutesComponents({ userId, setShouldAuth, shouldAuth, customDomain, domainChecked, setNewSignup }) {

  const [loading, setLoading] = createSignal(true);
  const [canAccess, setCanAccess] = createSignal(false);

  createEffect(() => {
    checkAccess(setCanAccess, setLoading);
  });

  return (
    <>

      <Show when={loading()}>
        <div className="h-screen w-screen flex justify-center items-center"><i className="far fa-spinner fa-spin text-5xl"></i></div>
      </Show>

      <Show when={!loading() && !canAccess()}>

        <div className="flex flex-col justify-center items-center min-h-screen px-4 sm:px-6 lg:px-8 box-border">
          <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-4 text-center break-words max-w-sm sm:max-w-md md:max-w-4xl mx-auto">
            Contact
            <a
              href="mailto:info@zapt.ai"
              className="text-purple-600 hover:text-purple-500 px-1 sm:px-3 transition-colors duration-300 whitespace-nowrap"
            >
              info@zapt.ai
            </a>
            for access
          </p>
          <div className="mt-4 sm:mt-6">
            <button
              className="flex items-center justify-center gap-x-2 sm:gap-x-4 bg-purple-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 active:bg-gray-800 text-white text-xs sm:text-sm font-bold py-2 px-3 sm:px-4 rounded transition-colors duration-300 w-full sm:w-auto"
              onClick={handleSignOut}
              aria-label="Logout"
            >
              <i className="far fa-sign-out-alt"></i>
              Logout
            </button>
          </div>
        </div>


      </Show>


      <Show when={!loading() && canAccess() && domainChecked()}>
        <Routes>
          <Route path="/deployed-app/:appId" component={() => <DeployedApp shouldAuth={shouldAuth} setShouldAuth={setShouldAuth} />} />
          <Route path="/ai-builder/:appId/:currentVersionId" component={() => <AiBuilder />} />
          <Route path="/create-app/:appId" component={() => <CreateApp user_id={userId()} />} />
          <Route path="/create-new-app" component={() => <CreateNewApp user_id={userId()} setShouldAuth={setShouldAuth} />} />

          <Route path="/landing-page/:appId" component={() => <LandingPage />} />

          <Route path="/reset-password" component={() => <ResetPasswordForm />} />
          <Route path="/settings" component={() => <Settings user_id={userId()} />} />

          <Route path="/app-settings/:appId" component={() => <AppSettings user_id={userId()} />} />

          <Route path="/app-details/:appId" component={() => <AppDetails user_id={userId()} />} />
          <Route path="/user-plan" component={() => <UserPlan user_id={userId()} />} />
          <Route path="/app-icon/:appId" component={() => <AppIcon user_id={userId()} />} />

          <Route path="/app-price/:appId" component={() => <AppPrice user_id={userId()} />} />
          <Route path="/app-design/:appId" component={() => <AppDesign user_id={userId()} />} />
          <Route path="/app-config/:appId" component={() => <AppConfig user_id={userId()} />} />

          <Route path="/list-apis" component={() => <ApisList user_id={userId()} />} />
          <Route path="/view-api/:apiId" component={() => <Api user_id={userId()} />} />
          <Route path="/list-api-keys" component={() => <ApiKeysList user_id={userId()} />} />
          <Route path="/api-key/:apiKeyId?" component={() => <ApiKey user_id={userId()} />} />
          <Route path="/edit-api/:apiId?" component={() => <ApiEdit user_id={userId()} />} />

          <Route path="/databases" component={() => <Databases user_id={userId()} />} />
          <Route path="/database/:databaseId" component={() => <Database user_id={userId()} />} />
          <Route path="/database/:databaseId/settings" component={() => <DatabaseSettings user_id={userId()} />} />
          <Route path="/database/:databaseId/data" component={() => <DatabaseData user_id={userId()} />} />
          <Route path="/database/:databaseId/structure" component={() => <DatabaseStructureView user_id={userId()} />} />
          <Route path="/database/:databaseId/structure/edit" component={() => <DatabaseStructureEdit user_id={userId()} />} />

          <Route path="/custom-domain/:appId" component={() => <CustomDomain user_id={userId()} />} />
          <Route path="/app-data/:appId" component={() => <AppData user_id={userId()} />} />

          <Route path="/bundles" component={() => <Bundles user_id={userId()} />} />
          <Route path="/bundle/:bundleId/settings" component={() => <Bundle user_id={userId()} />} />
          <Route path="/bundle/:bundleId/edit-apps" component={() => <BundleEditApps user_id={userId()} />} />
          <Route path="/bundle/:bundleId" component={() => <BundleViewApps user_id={userId()} setShouldAuth={setShouldAuth} setNewSignup={setNewSignup} />} />
          <Route path="/bundle/:bundleId/custom-domain" component={() => <BundleCustomDomain user_id={userId()} />} />
          <Route path="/bundle/:bundleId/edit-price" component={() => <BundleEditPrice user_id={userId()} />} />
          <Route path="/bundle/:bundleId/allowed-users" component={() => <BundleAllowedUsers user_id={userId()} />} />

          <Route path="/teacher-portal" component={() => <TeacherPortal user_id={userId()} />} />
          <Route path="/api-app-access/:appId" component={() => <ApiAppAccess user_id={userId()} />} />

          <Route path="/create-app-database/:appId/:versionId" component={() => <CreateAppDatabase user_id={userId()} />} />
          <Route path="/create-app-database-new/:appId/:versionId" component={() => <CreateAppDatabaseNew user_id={userId()} />} />
          <Route path="/create-app-change-version/:appId/:versionId" component={() => <CreateAppChangeVersion user_id={userId()} />} />
          <Route path="/create-app-change-version-confirm/:appId/:versionId/:newVersionId" component={() => <CreateAppChangeVersionConfirm user_id={userId()} />} />
          <Route path="/create-app-deploy/:appId/:versionId" component={() => <CreateAppDeploy user_id={userId()} />} />

          <Route path="/create-app/:appId/:versionId" component={() => <CreateAppSteps user_id={userId()} />} />
          <Route path="/create-app/:appId/:versionId/:stepId" component={() => <CreateAppStep user_id={userId()} />} />
          <Route path="/create-app-ai-changes/:appId" component={() => <CreateAppAiChanges user_id={userId()} />} />
          <Route path="/create-app/:appId/:versionId/:stepId/delete" component={() => <CreateAppStepDelete user_id={userId()} />} />
          <Route path="/create-app/:appId/:versionId/:stepId/next-step-selector" component={() => <CreateAppStepNextSelector user_id={userId()} />} />

          <Route path="/app-builder" component={() => <AppBuilder />} />
          <Route path="/app-builder-view/:appId" component={() => <AppBuilderView />} />
          <Route path="/app-builder-faq" component={() => <AppBuilderFaq />} />
          <Route path="/app-builder-custom-domains/:appId" component={() => <AppBuilderCustomDomains />} />
          <Route path="/app-builder-app-price/:appId" component={() => <AppBuilderAppPrice user_id={userId()} />} />
          <Route path="/app-builder-app-icon/:appId" component={() => <AppBuilderAppIcon user_id={userId()} />} />
          <Route path="/app-builder-settings/:appId" component={() => <AppBuilderSettings user_id={userId()} />} />
          <Route path="/app-builder-env-vars/:appId" component={() => <AppBuilderEnvVars />} />
          <Route path="/app-builder-view-errors/:appId" component={() => <AppBuilderViewErrors />} />
          <Route path="/app-builder-view-stage-development/:appId" component={() => <AppBuilderViewStageDevelopment />} />
          <Route path="/app-builder-view-stage-production/:appId" component={() => <AppBuilderViewStageProduction />} />
          <Route path="/app-builder-database-viewer/:appId" component={() => <AppBuilderDatabaseViewer />} />

          <Route
            path="/"
            component={() => {
              if (!customDomain()) return <Home user_id={userId()} />

              if(customDomain().is_landing_page) return <LandingPage app_id={customDomain().app_id} />
              if(customDomain().is_app) return <DeployedApp app_id={customDomain().app_id} setShouldAuth={setShouldAuth} shouldAuth={shouldAuth} />
              if(customDomain().is_bundle) return <BundleViewApps bundle_id={customDomain().bundle_id} setShouldAuth={setShouldAuth} setNewSignup={setNewSignup} />             
            }}
          />

        </Routes>
      </Show>
    </>
  );
};

import { supabase } from '../supabaseClient';
import * as Sentry from "@sentry/browser";

function getReferralAppId() {
    const params = new URLSearchParams(window.location.search);
    const referralAppId = params.get("referral_app_id");

    if (referralAppId) {
        return referralAppId;
    }

    let url = window.location.href;
    let urlObject = new URL(url);

    if(urlObject.hostname === 'app.zapt.ai' || urlObject.hostname === 'use.mapt.events') {
        let parts = urlObject.pathname.split('/');
        let appId = parts[parts.length - 1];
        return appId;
    }

    return null;
}

export default async function createUser(){
    const referral_app_id = getReferralAppId()

    // First, check if the user already exists
    let { data, error } = await supabase.from('User').select('id');
    if(error) throw error;

    // If user does not exist, then create a new user
    if(!data.length) {
        // let { error } = await supabase.from('User').insert({id, email, referral_app_id: referralAppId});
        const { error } = await supabase.functions.invoke('add-user', { body: { referral_app_id } });
        if(error) Sentry.captureException(error);
    }
}

import { supabase } from '../supabaseClient';

async function getIntercomHmac(){
    let { data, error } = await supabase.from('User').select('intercom_hmac').single();
    if(error) throw error;
    return data.intercom_hmac;
}

export default async function addIntercom(email){

    if (process.env.NODE_ENV !== 'production'){
        console.log("Intercom is only available in production mode");
        return;
    }

    const user_hash = await getIntercomHmac();

    const APP_ID = "mj9vqjm3";

    window.intercomSettings = {
        app_id: APP_ID,
        // name: current_user_name, // Full name
        email, // Email address
        // user_id: current_user_id // current_user_id
        user_hash,
        hide_default_launcher: true
      };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}